<template>
  <div class="govern-edit common-page">
    <Spin size="large" fix v-if="searchLoading"></Spin>
    <header class="page-header">
      <div @click="goBackForCheck" class="header-left">
        <Icon class="back-icon" type="md-arrow-round-back" />
        <span class="back-title">返回{{params.backTitle}}</span>
      </div>
      <div class="header-title">编辑治理单</div>
    </header>
    <section v-if="alarmData" class="page-content">
      <div class="govern-content">
        <div class="govern-notice">
          <!-- <div class="notice-item">
            <span class="notice-label">治理通报:</span><span class="notice-value">{{alarmData.noticeName}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">通报周期:</span><span class="notice-value">{{alarmData.noticePeriod}}</span>
          </div> -->
          <div class="notice-item">
            <span class="notice-label">治理单状态:</span><span class="notice-value">{{governStatus.find(item => item.value == alarmData.status).label}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理单号:</span><span class="notice-value">{{alarmData.governId}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理车辆:</span><span class="notice-value">{{`${alarmData.vehicleNo}(${alarmData.vehiclePlateColor})`}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">所属企业:</span><span class="notice-value">{{alarmData.unitName}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理对象:</span>
            <span class="notice-value driver-value">{{driversName.map(item => item.label.split('-')[0]).join(', ')}}</span>
            <span v-if="btnPrivilegeCode.governance.includes('update')" @click="addDriverModal = true" class="btn-span left-span">编辑</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理罚单:</span>
            <span class="notice-value">
              <!-- <span class="btn-span">下载罚单</span> -->
              <span class="btn-span" @click="previewNotice">在线预览</span>
              <span v-if="btnPrivilegeCode.governance.includes('ResetTicket')" class="btn-span" @click="checkSetGovern">重开罚单</span>
            </span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理证据附件:</span>
            <span class="notice-value">
              <Upload
                ref="upload"
                :action="uploadUrl"
                :default-file-list="defalutFileList"
                :headers="uploadHeader"
                :before-upload="beforeFileUpload"
                :on-success="fileUploadFinish"
                :on-error="fileUploadFinish"
                multiple>
                <span class="btn-span">选择文件</span>
              </Upload>
            </span>
          </div>
          <div class="notice-item">
            <span class="notice-label"></span>
            <Button v-if="alarmData.status == 3 && isFinishUpload" @click="saveForGoverning" :loading="searchLoading" type="primary" size="small">保存附件信息</Button>
          </div>
          <!-- <div class="notice-item">
            <span class="notice-label">线上处理结果:</span><span class="notice-value"></span>
          </div> -->
        </div>
        <div class="govern-content">
          <template v-for="item in alarmData.contents">
            <div v-if="item.alarms.length > 0" :key="item.alarmType" class="detail-item">
              <header class="item-title">违章类型: {{alarmType.find(alarmItem => alarmItem.value == item.alarmType).label}}</header>
              <div class="govern-notice">
                <!-- <div class="notice-item">
                  <span class="notice-label">所属治理性质:</span><span class="notice-value">{{governCharacter.find(gItem => gItem.value == item.governCharacter).label}}</span>
                </div> -->
                <div class="notice-item">
                  <span class="notice-label">违章条数:</span><span class="notice-value">{{item.alarms.length}}</span>
                </div>
                <div v-if="item.governType" class="notice-item">
                  <span class="notice-label">治理方式:</span><span class="notice-value">{{getPunishByCode(item.governType.punishIds).join(',')}}</span>
                </div>
              </div>
              <div class="item-table">
                <Table :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns" :data="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).data" size="small" max-height="300">
                </Table>
              </div>
            </div>
          </template>
        </div>
        <div class="detail-item">
          <div class="btn-wrapper">
            <!-- <Button v-if="governBtnType.includes(alarmData.status)" @click="staging" :loading="searchLoading" class="option-btn" type="primary">暂存</Button> -->
            <Button v-if="governBtnType.includes(alarmData.status) && btnPrivilegeCode.governance.includes('update')" @click="submit" :loading="searchLoading"  class="option-btn" type="primary">提交</Button>
            <!-- <Button v-if="alarmData.status == 3" @click="saveForGoverning" :loading="searchLoading"  class="option-btn" type="primary">提交</Button> -->
            <Button v-if="btnPrivilegeCode.governance.includes('Revoke')" @click="repeal" :loading="searchLoading" class="option-btn" type="warning">撤销</Button>
          </div>
        </div>
      </div>
    </section>
    <!-- 添加驾驶员对象 -->
    <Modal v-model="addDriverModal" title="添加驾驶员对象" :footer-hide="true" width="960">
      <div v-if="alarmData" class="transfer-wrapper">
        <GovernDriver :governId="alarmData.governId" :unitId="alarmData.unitId" :defaultDrivers="alarmData.drivers" @setDriverName="setDriverName"></GovernDriver>
      </div>
    </Modal>
    <!-- 预览罚单 -->
    <Modal v-model="ticketModal" title="预览罚单" :footer-hide="true" class="common-modal" width="80">
      <div v-if="ticketModal" class="ticket-wrapper">
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" title="违章告知书(公司留底)"></IllegalTicketTemplate>
        <Divider :dashed="true"/>
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" title="违章告知书(当事人)"></IllegalTicketTemplate>
      </div>
    </Modal>
    <!-- 播放视频 -->
    <Modal :footer-hide="true" width="772" v-model="videoModal" :title="videoForm.title">
      <video v-if="videoModal" width="740" height="500" autoplay controls>
        <source :src="videoForm.url" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
    </Modal>
  </div>
</template>
<script>
import {btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, dangerousDrivingBehavior} from '@/assets/js/mixin.js'
import GovernDriver from '@/components/govern-driver/index.vue'
import IllegalTicketTemplate from '../../illegal/illegal_create/illegal_ticket_template/index.vue'
import {getToken} from '@/assets/js/sessionStorage.js'
import axios from 'axios'
export default {
  mixins: [btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, dangerousDrivingBehavior],
  components: {
    GovernDriver,
    IllegalTicketTemplate
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          governId: '',
          beginTime: '',
          endTime: '',
          backTitle: '',
          backComponentName: ''
        }
      }
    }
  },
  data () {
    return {
      // 添加治理对象
      alarmData: null,
      addDriverModal: false,
      driversName: [],
      searchLoading: false,
      governBtnType: [4, 5, 7], // 只有待审核、未通过、未提交才显示暂存和提交
      ticketModal: false,
      isFinishUpload: true
    }
  },
  computed: {
    uploadUrl() {
      return `${WEB_CONFIG.BASE_IP}/govern-web/file/uploadToObs`
    },
    uploadHeader() {
      return {
        Authentication: getToken()
      }
    },
    defalutFileList() {
      return this.alarmData.governFiles.map(item => {
        return {
          name: item.fileName,
          url: item.url
        }
      })
    }
  },
  created () {
    this.getGovernDetailData()
  },
  methods: {
    // 获取治理单详情数据
    async getGovernDetailData () {
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'get',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/governDetail/${this.params.governId}`
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.alarmData = data.data
          this.initDriver()
          this.alarmTableColmuns.forEach(item => {
            this.alarmData.contents.forEach(aItem => {
              // 为每个违章对象添加编辑form表单
              let _form = {
                punishId: [],
                punishTime: '',
                runTime: '',
                stopTime: '',
                punishMoney: 0,
                remark: ''
              }
              if (item.id == aItem.alarmType) {
                item.data = aItem.alarms
                // 为每个违章对象添加编辑form表单
                if (aItem.governType) {
                  // 如果治理类型数据存在
                  _form = {
                    punishId: aItem.governType.punishIds,
                    punishTime: [aItem.governType.runTime, aItem.governType.stopTime],
                    runTime: aItem.governType.runTime,
                    stopTime: aItem.governType.stopTime,
                    punishMoney: aItem.governType.amount,
                    remark: aItem.governType.remark
                  }
                }
                this.$set(aItem, 'editForm', _form)
              }
            })
          })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取治理单详情失败, 请稍后重试!')
        }
      }
    },
    // 重开罚单
    checkSetGovern () {
      this.$Modal.confirm({
        title: '重开罚单',
        content: `重开罚单将对现有治理单内容重置,是否继续？`,
        onOk: async () => {
          this.resetGovern()
        },
        onCancel: () => {}
      })
    },
    // 重开罚单
    async resetGovern() {
      let _params = {
        appKey: this.alarmData.appKey,
        governId: this.alarmData.governId,
        // noticeId: this.alarmData.noticeId,
        beginTime: this.alarmData.governBeginTime,
        endTime: this.alarmData.governEndTime,
        unitId: this.alarmData.unitId,
        vehicleNo: this.alarmData.vehicleNo,
        vehiclePlateColor: this.alarmData.vehiclePlateColor
      }
      try {
        this.searchLoading = true
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/resetGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          let params = {
            step: 1,
            noticeId: _params.noticeId,
            beginTime: _params.beginTime,
            endTime: _params.endTime,
            vehicle : `${_params.vehicleNo}-${_params.vehiclePlateColor}`
          }
          this.$Message.success('重开罚单成功')
          this.$emit('reSetGoverns', params)
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('重开罚单失败, 请稍后重试!')
        }
      }
    },
    // 在线预览
    previewNotice() {
      this.ticketModal = true
    },
    // 初始化驾驶员对象
    initDriver () {
      this.driversName = this.alarmData.drivers.map(item => {
        return {
          key: item.id,
          label: `${item.name}-${item.dqId}-${item.tel}`,
          ...item
        }
      })
    },
    // 设置驾驶员对象
    setDriverName (driversName) {
      this.driversName = driversName
    },

    // 文件上传
    beforeFileUpload () {
      this.isFinishUpload = false
    },
    fileUploadFinish () {
      this.isFinishUpload = true
    },

    // 暂存
    async staging () {
      // 文件列表
      let _fileList = this.$refs.upload.fileList.map(item => {
        let result = null
        if (item.response) {
          // 本次上传
          result = {
            fileName: item.response.data.fileName,
            url: item.response.data.fileURL,
          }
        } else {
          // 缓存请求的数据
          result = {
            fileName: item.name,
            url: item.url,
          } 
        }
        return result
      })
      if(_fileList.length > 8){        
        this.$Message.warning('文件数量不能超过8个!')
        return
      } 
      // 驾驶员对象
      let _driver = this.driversName.map(item => {
        return {
          appKey: item.appKey,
          driverId: item.id,
          governId: this.alarmData.governId
        }
      })
      let _params = {
        governId: this.alarmData.governId,
        filePathList: _fileList,
        personList: _driver
      }
      try {
        this.searchLoading = true
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/save/storageGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.$Message.success('暂存成功')
          this.goBack()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('暂存失败, 请稍后重试!')
        }
      }
    },
    // 提交
    async submit () {
      // 文件列表
      let _fileList = this.$refs.upload.fileList.map(item => {
        let result = null
        if (item.response) {
          // 本次上传
          result = {
            fileName: item.response.data.fileName,
            url: item.response.data.fileURL,
          }
        } else {
          // 缓存请求的数据
          result = {
            fileName: item.name,
            url: item.url,
          } 
        }
        return result
      })
      if(_fileList.length == 0){
        this.$Message.warning('文件不能为空！')
        return
      } else if(_fileList.length>8) {
        this.$Message.warning('文件数量不能超过8个!')
        return 
      }
      // 驾驶员对象
      let _driver = this.driversName.map(item => {
        return {
          appKey: item.appKey,
          driverId: item.id,
          governId: this.alarmData.governId
        }
      })
      let _params = {
        governId: this.alarmData.governId,
        filePathList: _fileList,
        personList: _driver
      }
      try {
        this.searchLoading = true
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/save/submitGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.$Message.success('提交成功')
          this.goBack()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('提交失败, 请稍后重试!')
        }
      }
    },
    // 治理中 保存文件列表
    async saveForGoverning () {
      // 文件列表
      let _fileList = this.$refs.upload.fileList.map(item => {
        let result = null
        if (item.response) {
          // 本次上传
          result = {
            fileName: item.response.data.fileName,
            url: item.response.data.fileURL,
          }
        } else {
          // 缓存请求的数据
          result = {
            fileName: item.name,
            url: item.url,
          } 
        }
        return result
      })
      if(_fileList.length == 0){
        this.$Message.warning('文件不能为空！')
        return
      }else if(_fileList.length>8) {
        this.$Message.warning('文件数量不能超过8个!')
        return 
      }
      // 驾驶员对象
      let _driver = this.driversName.map(item => {
        return {
          appKey: item.appKey,
          driverId: item.id,
          governId: this.alarmData.governId
        }
      })
      let _params = {
        governId: this.alarmData.governId,
        filePathList: _fileList,
        personList: _driver
      }
      try {
        this.searchLoading = true
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/save/inGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.$Message.success('提交成功')
          this.getGovernDetailData()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('提交失败, 请稍后重试!')
        }
      }
    },
    // 撤销
    repeal () {
      this.$Modal.confirm({
        title: '撤销',
        content: '撤销操作会删除治理单及附带罚单内容,是否继续？',
        onOk: async () => {
          this.searchLoading = true
          try {
            let {data} = await axios({
              method: 'get',
              url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/removeGovern/${this.alarmData.governId}`
            })
            this.searchLoading = false
            if (data.code === 200) {
              this.$Message.success('撤销成功')
              this.goBack()
            } else {
              this.$Message.warning(data.msg)
            }
          } catch (error) {
            this.searchLoading = false
            if (!this.checkPrivilege(error)) {
              this.$Message.error('撤销失败, 请稍后重试!')
            }
          }
        },
        onCancel: () => {}
      })
    },
    goBackForCheck () {
      if (this.alarmData.status == 8) {
        // 治理已通过 直接返回
        this.goBack()
      } else {
        this.$Modal.confirm({
          title: '返回',
          content: '治理单尚未建立成功,是否确认离开？',
          onOk: async () => {
            this.goBack()
          },
          onCancel: () => {}
        })
      }
    },
    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.govern-edit {
  position: relative;
  height: 100%;
  width: 100%;
  .govern-content {
    .btn-span {
      margin-right: 15px;
    }
    .left-span {
      margin-left: 15px;
    }
    .driver-value {
      max-width: calc(100% - 200px);
    }
  }
}
</style>